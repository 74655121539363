import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";

import Heading from "./Heading.tsx";
import FooterLinks from "./FooterLinks.tsx";

const rootElem = document.getElementById("root");

if (rootElem) {
  const root = ReactDOM.createRoot(rootElem);
  root.render(
    <React.StrictMode>
      <Heading />
      <FooterLinks />
    </React.StrictMode>
  );
}
