import React, { useState, useEffect } from "react";

import "./IpInfo.css";

type IpData = {
  ipVersion: 4 | 6;
  ipAddress: string;
};

const Loading = () => <span>🔗 Loading...</span>;

const IpInfoDisplay: React.FC<{
  ipData: IpData | undefined;
  showIpAddress: boolean;
  handleShowIpAddress: () => void;
}> = ({ ipData, showIpAddress, handleShowIpAddress }) => (
  <span
    className={"ip-info " + (showIpAddress ? "expanded" : "")}
    onClick={handleShowIpAddress}
  >
    🔗 {ipData?.ipVersion === 6 ? "IPv6" : "IPv4"}
    {showIpAddress && <span> {ipData?.ipAddress}</span>}
  </span>
);

export default function IpInfo() {
  const [ipData, setIpData] = useState<IpData | undefined>(undefined);
  const [showIpAddress, setShowIpAddress] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    fetch("/api/ip-mirror")
      .then((response) => response.json())
      .then((ipDataRes) => {
        setIpData(ipDataRes);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const handleShowIpAddress = () => {
    setShowIpAddress(!showIpAddress);
  };

  if (loading) return <Loading />;
  if (error) return <span>🔗 Unavailable</span>;

  return (
    <IpInfoDisplay
      ipData={ipData}
      showIpAddress={showIpAddress}
      handleShowIpAddress={handleShowIpAddress}
    />
  );
}
