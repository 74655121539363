import * as React from "react";

import "./FooterLinks.css";

import IpInfo from "./IpInfo.tsx";

export default function FooterLinks() {
  return (
    <div className="footer-links">
      <div>
        <a href="https://twitter.com/justinappler">X</a>|
        <a href="https://www.linkedin.com/in/justinappler/">LinkedIn</a>|
        <a href="https://github.com/justinappler">GitHub</a>
      </div>
      <IpInfo />
    </div>
  );
}
